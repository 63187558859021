import { Submission, VoteableContent } from 'snoowrap'

enum VoteDirection {
  up,
  down
}

// TODO: Figure out how to generic this
interface VoteProps {
  direction: VoteDirection
  content: VoteableContent<Submission>
  preVote?: () => void
  postVote?: (postVoteSubmission: VoteableContent<Submission>) => void
}

const onVoteMutation = ({ direction, content, preVote, postVote }: VoteProps) => async (likes: boolean | null) => {

    preVote && await preVote()

    // Snapshot the previous value
    const oldSubmission = content

    let scoreDelta = 0
    let newLike: boolean | null = null
    if (direction === VoteDirection.up) {
      newLike = true
      if (likes === true) {
        // upvote to no vote
        scoreDelta = -1
        newLike = null
      } else if (likes === null) {
        // no vote to upvote
        scoreDelta = 1
      } else {
        // downvote to upvote
        scoreDelta = 2
      }
    } else {
      newLike = false
      if (likes === true) {
        // upvote to downvote
        scoreDelta = -2
      } else if (likes === null) {
        // no vote to downvote
        scoreDelta = -1
      } else {
        // downvote to no vote
        scoreDelta = 1
        newLike = null
      }
    }

    const newSubmission = {
      ...oldSubmission,
      likes: newLike,
      score: oldSubmission.score + scoreDelta
    } as Submission

    postVote && await postVote(newSubmission)

    // Return a context with the previous and new submission
    return { oldSubmission, newSubmission }
  }

export { onVoteMutation, VoteDirection }
