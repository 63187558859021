import { useContext, createContext, useState } from 'react'

interface Theme {
  primaryTextColor: string
  secondaryTextColor: string
  tertiaryTextColor: string
  primaryBackgroundColor: string
  secondaryBackgroundColor: string
  tertiaryBackgroundColor: string
}

const defaultTheme: Theme = {
  primaryTextColor: '',
  secondaryTextColor: '',
  tertiaryTextColor: '',
  primaryBackgroundColor: '',
  secondaryBackgroundColor: '',
  tertiaryBackgroundColor: ''
}

interface ThemeContextProps {
  theme: Theme
  setTheme: (theme: Theme) => void
}

const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps)

function ThemeClient() {
  const [theme, setTheme] = useState<Theme>(defaultTheme)

  const setNewTheme = (theme: Theme) => setTheme(theme)

  return { theme, setTheme: setNewTheme }
}

export function useTheme() {
  return useContext(ThemeContext)
}

export function ThemeProvider({ children }: ReactChildrenProps) {
  const theme = ThemeClient()
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}
