import { useRef, useState } from 'react'
import { useQuery, useInfiniteQuery } from 'react-query'
import { Listing, Submission, Subreddit } from 'snoowrap'
import styled, { keyframes } from 'styled-components'
import LineIcon from 'react-lineicons'
import { useHistory } from 'react-router'

import { useSnoowrap } from '@contexts/SnoowrapContext'
import { useAuth, Direction } from '@contexts/AuthContext'

import { VirtualList, VirtualListHandle } from '@components/VirtualList'

import SubmissionRow from './components/SubmissionRow'

interface ContainerProps {
  isSubmissionView: boolean | null
  columnWidth?: number
}

const StyledVirtualList = styled(VirtualList)`
  margin: 0;
  overflow: auto;
`

const Container = styled.div<ContainerProps>`
  width: ${props => (props.columnWidth ? `${props.columnWidth}px` : '310px')};
  flex-shrink: 0;
  border: 1px solid #dddddd;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: ${props => (props.isSubmissionView ? '0' : '6px')};
  background-color: #ffffff;
`

const Header = styled.div`
  background-color: #efefef;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
  font-weight: 600;
  height: 50px;
  flex-shrink: 0;
  cursor: pointer;
`

const ColumnIcon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
  border-radius: 50% 50%;
  margin-right: 6px;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > i {
    font-size: 24px;
    color: #3c3c3c;
    animation: ${rotate} 1s linear infinite;
  }
`

export interface ColumnProps {
  id: string
  subreddit?: string
  searchQuery?: string
  user?: string
  activeSubmissionID?: string | null
  inSubmissionView?: boolean
}

export function Column({
  id,
  subreddit,
  searchQuery,
  user,
  activeSubmissionID,
  inSubmissionView
}: ColumnProps) {
  const snoowrap = useSnoowrap()
  const history = useHistory()
  const auth = useAuth()

  const onClickHeader = () => {
    history.push(`/r/${subreddit}`)
  }

  let headerOptions: ColumnHeaderProps = {
    id,
    title: subreddit ?? user ?? `Search: ${searchQuery}` ?? '',
    onClick: onClickHeader,
    hideControls: inSubmissionView ?? false
  }

  // grab general subreddit info
  const { data: subredditInfo } = useQuery<Subreddit>(
    ['column', subreddit],
    () => snoowrap.getClient()!.getSubreddit(subreddit!),
    {
      enabled: snoowrap.getClient() !== null && !!subreddit,
      refetchOnWindowFocus: false
    }
  )
  if (subredditInfo) {
    headerOptions.title = subredditInfo.display_name
    headerOptions.iconURL = subredditInfo.community_icon
  }

  // get currently displayed listing
  let listing: Listing<Submission> | undefined
  const { fetchNextPage, isLoading } = useInfiniteQuery<Listing<Submission>>(
    ['column', subreddit ?? searchQuery ?? user, 'submissions'],
    ({ pageParam }) => {
      const client = snoowrap.getClient()!

      // paginate if we already have a listing
      if (pageParam && listing) {
        return listing.fetchMore({ amount: 25 })
      } else {
        if (subreddit) {
          return client.getSubreddit(subreddit).getHot()
        } else if (searchQuery) {
          return client.search({ query: searchQuery ?? '' })
        } else {
          return client.getUser(user!).getSubmissions() // TODO: add support for comments
        }
      }
    },
    {
      getNextPageParam: lastPage => {
        // set listing with new submissions fetched
        listing = lastPage
        return !lastPage.isFinished
      },
      keepPreviousData: true,
      enabled: snoowrap.getClient() !== null,
      refetchOnWindowFocus: false,
      structuralSharing: false
    }
  )

  const virtualRef = useRef<VirtualListHandle>(null)

  const [hasMeasured, setHasMeasured] = useState(false)

  return (
    <Container
      isSubmissionView={inSubmissionView || null}
      columnWidth={auth.currentUser?.settings.columnWidth}
    >
      <ColumnHeader {...headerOptions} />
      {isLoading && (
        <LoadingContainer>
          <LineIcon name="spinner-solid" />
        </LoadingContainer>
      )}
      {!isLoading && listing && (
        <StyledVirtualList
          ref={virtualRef}
          rows={listing}
          hasMore={!listing.isFinished}
          loadMore={() => {
            fetchNextPage()
          }}
        >
          {({ virtualRow }) => {
            const submission = listing![virtualRow.index]
            return (
              <SubmissionRow
                key={submission.id}
                selected={submission.id === activeSubmissionID}
                submission={submission}
                onLoadMedia={() => {
                  if (!hasMeasured) {
                    virtualRef.current?.measure()
                    setHasMeasured(true)
                  }
                }}
              />
            )
          }}
        </StyledVirtualList>
      )}
    </Container>
  )
}

interface ColumnHeaderProps {
  id: string
  title: string
  iconURL?: string
  onClick?: () => void
  hideControls: boolean
}

const Button = styled.div`
  display: flex;
  cursor: pointer;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  & > i {
    color: #000000;
    font-size: 14px;
    font-weight: bolder;
  }
`

const Title = styled.span`
  margin-right: auto;
`

function ColumnHeader({
  id,
  title,
  iconURL,
  onClick,
  hideControls
}: ColumnHeaderProps) {
  const auth = useAuth()

  const onRemove: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void = e => {
    e.stopPropagation()
    auth.columnManager.remove(id)
  }

  const move =
    (direction: Direction) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      auth.columnManager.move(id, direction)
    }

  return (
    <Header onClick={onClick}>
      {iconURL && <ColumnIcon src={iconURL} />}
      <Title>{title}</Title>
      {!hideControls && (
        <>
          <Button onClick={move(Direction.Left)}>
            <LineIcon name="chevron-left" />
          </Button>
          <Button onClick={move(Direction.Right)}>
            <LineIcon name="chevron-right" />
          </Button>
          <Button onClick={onRemove}>
            <LineIcon name="close" />
          </Button>
        </>
      )}
    </Header>
  )
}
