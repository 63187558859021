import { useState } from 'react'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'

import { useAuth } from '@contexts/AuthContext'

import Modal from '@components/Modal'

import AddAccount from './components/AddAccount'

const RaisedButton = styled.button`
  border: none;
  padding: 15px 30px;
  display: block;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 14px;
  color: #000000;

  &:hover {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
`

const Title = styled.h1`
  margin: 0;
`

const UserList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const UserRow = styled.li`
  height: 64px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    background: #eeeeee;
  }

  & > img {
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
    margin-right: 6px;
  }
`

const UserName = styled.span`
  margin-right: 6px;
`

interface ManageUsersProps {
  onDismiss: () => void
}

function ManageUsers({ onDismiss }: ManageUsersProps) {
  const auth = useAuth()

  const [showAddAccount, setShowAddAccount] = useState(false)
  const queryClient = useQueryClient()

  return (
    <Modal onClick={onDismiss}>
      <Title>Manage Users</Title>
      <UserList>
        {auth.users.map(account => {
          return (
            <UserRow
              key={account.refreshKey}
              onClick={() => {
                auth.setCurrentUser(account)
                queryClient.clear()
                onDismiss()
              }}
            >
                {account.redditAccount?.icon_img && (
                  <img src={account.redditAccount?.icon_img} />
                )}
              <UserName>{account.redditAccount?.name}</UserName>
              <span>
                {account.redditAccount?.link_karma} |{' '}
                {account.redditAccount?.comment_karma}
              </span>
            </UserRow>
          )
        })}
      </UserList>
      <RaisedButton
        onClick={e => {
          e.stopPropagation()
          setShowAddAccount(true)
        }}
      >
        Add Account
      </RaisedButton>
      {showAddAccount && <AddAccount />}
    </Modal>
  )
}

export default ManageUsers
