import { createContext, useContext, useState } from 'react'

import { AuthProvider } from '@contexts/AuthContext'
import { BannerProvider } from '@contexts/BannerContext'
import { SnoowrapProvider } from '@contexts/SnoowrapContext'
import { ThemeProvider } from '@contexts/ThemeContext'

interface AppClient {
  isShowingCreatePost: boolean
  showCreatePost: (show: boolean) => void
  isShowingSettings: boolean
  showSettings: (show: boolean) => void
  isAddingColumn: boolean
  showAddColumn: (show: boolean) => void
}

const AppContext = createContext<AppClient>({} as AppClient)

export function useApp() {
  return useContext(AppContext)
}

function AppClientContext(): AppClient {
  const [isShowingCreatePost, setShowCreatePost] = useState(false)
  const [isShowingSettings, setShowSettings] = useState(false)
  const [isAddingColumn, setAddingColumn] = useState(false)

  const showCreatePost = (show: boolean) => {
    setShowCreatePost(show)
  }

  const showSettings = (show: boolean) => {
    setShowSettings(show)
  }

  const showAddColumn = (show: boolean) => {
    setAddingColumn(show)
  }

  return {
    isShowingCreatePost,
    showCreatePost,
    isShowingSettings,
    showSettings,
    isAddingColumn,
    showAddColumn
  }
}

function AppProvider({ children }: ReactChildrenProps) {
  const app = AppClientContext()
  return <AppContext.Provider value={app}>{children}</AppContext.Provider>
}

export const AppContextProvider = ({ children }: ReactChildrenProps) => {
  return (
    <AppProvider>
      <AuthProvider>
        <ThemeProvider>
          <BannerProvider>
            <SnoowrapProvider>{children}</SnoowrapProvider>
          </BannerProvider>
        </ThemeProvider>
      </AuthProvider>
    </AppProvider>
  )
}
