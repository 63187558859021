import { useRef, useCallback } from 'react'
import { useVirtual } from 'react-virtual'
import styled from 'styled-components'

import { useAuth } from '@contexts/AuthContext'

import { Column } from '@components/Column'

const Container = styled.div`
  height: 100vh;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

function Home() {
  const auth = useAuth()

  const parentRef = useRef<HTMLDivElement>(null)

  const columns = auth.currentUser.columns ?? []

  const columnVirtualizer = useVirtual({
    horizontal: true,
    size: columns.length,
    parentRef,
    estimateSize: useCallback(() => auth.currentUser.settings.columnWidth, [auth.currentUser.settings.columnWidth]),
    overscan: 2
  })

  return (
    <Container ref={parentRef}>
      <div
        style={{
          width: `${columnVirtualizer.totalSize}px`,
          height: '100%',
          position: 'relative'
        }}
      >
        {columns &&
          columnVirtualizer.virtualItems.map(virtualColumn => {
            const columnProps = columns[virtualColumn.index]
            return (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: `${virtualColumn.size}px`,
                  transform: `translateX(${virtualColumn.start}px)`
                }}
              >
                <Column key={virtualColumn.index} {...columnProps} />
              </div>
            )
          })}
      </div>
    </Container>
  )
}

export default Home
