import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LineIcon from 'react-lineicons'
import { useState } from 'react'

import logo from '@images/logo.png'
import { useApp } from '@contexts/AppContextProvider'
import { useAuth } from '@contexts/AuthContext'

import ManageUsers from '@screens/ManageUsers'

const Container = styled.div`
  background-color: #3f4245;
  width: 60px;
  height: 100vh;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`

const LogoLink = styled(Link)`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;

  & > img {
    width: 100%;
    object-fit: contain;
  }
`

const AddColumn = styled.div`
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    color: #efefef;
  }

  & > i {
    font-size: 32px;
  }
`

const CreatePost = styled.div`
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    color: #efefef;
  }

  & > i {
    font-size: 32px;
  }
`

const SettingsLink = styled.div`
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 24px;
  margin-bottom: auto;
  cursor: pointer;

  &:hover {
    color: #efefef;
  }

  & > i {
    font-size: 32px;
  }
`

const LogoutLink = styled(Link)`
  color: #ffffff;
  margin-top: auto;
  width: 100%;
  font-size: 32px;
  box-sizing: border-box;
  padding: 12px;
  text-align: center;
`

const Avatar = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  & > img {
    width: 100%;
    border-radius: 6px;
  }
`

function Sidebar() {
  const app = useApp()
  const auth = useAuth()
  const [showManageUsers, setShowManageUsers] = useState(false)

  const onClickCreatePost = () => {
    app.showCreatePost(true)
  }

  const onClickSettings = () => {
    app.showSettings(true)
  }

  const onClickAddColumn = () => {
    app.showAddColumn(true)
  }

  const onClickAvatar = () => {
    setShowManageUsers(true)
  }

  return (
    <Container>
      <LogoLink to="/">
        <img
          alt="Reditr Logo, small alien space ship in the shape of a saucer"
          src={logo}
        />
      </LogoLink>
      {auth.currentUser?.redditAccount && (
        <Avatar onClick={onClickAvatar}>
          <img src={auth.currentUser.redditAccount.icon_img} />
        </Avatar>
      )}
      <AddColumn onClick={onClickAddColumn}>
        <LineIcon name="circle-plus" />
      </AddColumn>
      <CreatePost onClick={onClickCreatePost}>
        <LineIcon name="add-files" />
      </CreatePost>
      <SettingsLink onClick={onClickSettings}>
        <LineIcon name="ruler" />
      </SettingsLink>
      <LogoutLink to="/logout">
        <LineIcon name="arrow-left-circle" />
      </LogoutLink>
      {showManageUsers && (
        <ManageUsers onDismiss={() => setShowManageUsers(false)} />
      )}
    </Container>
  )
}

export default Sidebar
