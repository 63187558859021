import React, { useRef, ReactNode, useImperativeHandle, useEffect } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { useVirtual, VirtualItem } from 'react-virtual'
import LineIcon from 'react-lineicons'

const StyledContainer = styled.div`
  height: 100%;
  flex-grow: 1;

  ul {
    padding: 0;
    margin: 0;
  }
`

const StyledParent = styled.div`
  height: 100%;
  overflow: auto;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const PageLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;

  & > i {
    font-size: 24px;
    color: #000000;
    animation: ${rotate} 1s linear infinite;
  }
`

interface VirtualListOverridesProps {
  paddingStart?: number
  paddingEnd?: number
}

type VirtualListProps = {
  className?: string
  children: (props: { virtualRow: VirtualItem }) => ReactNode
  hasMore?: boolean
  rows: any[]
  testId?: string
  loadMore?: () => void
  virtualListOverrides?: VirtualListOverridesProps
}

export type VirtualListHandle = {
  measure: () => void
}

function Loader({ loadMore }: { loadMore: () => void }): JSX.Element {
  useEffect(() => {
    loadMore()
  }, [loadMore])

  return (
    <PageLoadingContainer key="page-loading">
      <LineIcon name="spinner-solid" />
    </PageLoadingContainer>
  )
}

export const VirtualList = React.forwardRef<
  VirtualListHandle,
  VirtualListProps
>(
  (
    {
      children,
      className,
      rows,
      hasMore = false,
      loadMore,
      virtualListOverrides
    },
    ref
  ): JSX.Element | null => {
    const parentRef = useRef<HTMLDivElement>(null)
    const rowVirtualizer = useVirtual({
      size: hasMore ? rows.length + 1 : rows.length,
      parentRef,
      overscan: 2,
      ...virtualListOverrides
    })
    useImperativeHandle(ref, () => ({
      measure: () => {
        rowVirtualizer.measure()
      }
    }))

    if (!rows.length) return null

    return (
      <StyledContainer className={className}>
        <StyledParent ref={parentRef}>
          <div
            style={{
              width: '100%',
              height: `${rowVirtualizer.totalSize}px`,
              position: 'relative'
            }}
          >
            {rowVirtualizer.virtualItems.map(virtualRow => (
              <div
                key={virtualRow.index}
                ref={virtualRow.measureRef}
                style={{
                  width: '100%',
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`
                }}
              >
                {rows.length === virtualRow.index && loadMore ? (
                  <Loader loadMore={loadMore} />
                ) : (
                  children({ virtualRow })
                )}
              </div>
            ))}
          </div>
        </StyledParent>
      </StyledContainer>
    )
  }
)
