import { useContext, createContext, useReducer } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { RedditUser } from 'snoowrap'

import { ColumnProps } from '@components/Column'

import initialState from './initialState.json'

interface AuthedUser {
  refreshKey: string
  redditAccount?: RedditUser
  settings: AppSettings
  columns?: ColumnProps[]
}

interface AppSettings {
  columnWidth: number
}

interface AuthReducerState {
  users: AuthedUser[]
  currentUser: AuthedUser
}

interface AuthContext {
  users: AuthedUser[]
  currentUser: AuthedUser
  isAuthed: () => boolean
  setCurrentUser: (user: AuthedUser) => Promise<void>
  addUser: (user: AuthedUser) => void
  logout: () => Promise<void>
  setRedditAccount: (redditAccount: RedditUser) => void
  updateSettings: (settings: AppSettings) => void
  columnManager: ColumnManager
}

const Context = createContext<AuthContext>({} as AuthContext)

enum Direction {
  Left,
  Right
}

type Action =
  | { type: 'set-current-user'; user: AuthedUser }
  | { type: 'set-reddit-account'; redditAccount: RedditUser }
  | { type: 'add-user'; user: AuthedUser }
  | { type: 'logout' }
  | { type: 'update-settings'; settings: AppSettings }
  | { type: 'add-column'; column: ColumnProps }
  | { type: 'remove-column'; columnID: string }
  | { type: 'edit-column'; column: ColumnProps }
  | { type: 'move-column'; columnID: string; direction: Direction }

function authReducer(state: AuthReducerState, action: Action): AuthReducerState {
  const oldStateString = JSON.stringify(state)
  let newState = JSON.parse(oldStateString) as AuthReducerState
  switch (action.type) {
    case 'set-current-user':
      newState.currentUser = action.user
      break
    case 'add-user':
      newState.users.push(action.user)
      newState.currentUser = action.user
      break
    case 'set-reddit-account':
      if (newState.currentUser) {
        newState.currentUser.redditAccount = action.redditAccount
        newState.users = newState.users.filter(user => {
          return user.refreshKey !== newState.currentUser?.refreshKey
        })
        newState.users.push(newState.currentUser)
      }
      break
    case 'logout':
      newState.users = newState.users.filter(user => {
        return user.refreshKey !== newState.currentUser?.refreshKey
      })
      if (newState.users.length > 0) {
        newState.currentUser = newState.users[0]
      } else {
        newState = initialState
        localStorage.clear()
      }
      break
    case 'update-settings':
      if (newState.currentUser) {
        newState.currentUser.settings = action.settings
        newState.users = newState.users.filter(user => {
          return user.refreshKey !== newState.currentUser?.refreshKey
        })
        newState.users.push(newState.currentUser)
      }
      break
    case 'add-column':
      newState.currentUser?.columns?.push(action.column)
      break
    case 'remove-column':
      if (newState.currentUser) {
        newState.currentUser.columns = newState.currentUser?.columns?.filter(
          column => {
            return column.id != action.columnID
          }
        )
      }
      break
    case 'edit-column':
      if (newState.currentUser?.columns) {
        const index = newState.currentUser.columns
          .map(column => column.id)
          .indexOf(action.column.id)
        if (!!index) {
          newState.currentUser.columns[index] = action.column
        }
      }
      break
    case 'move-column':
      if (newState.currentUser?.columns) {
        const index = newState.currentUser.columns
          .map(column => column.id)
          .indexOf(action.columnID)

        // stay within bounds
        if (
          (action.direction === Direction.Left && index <= 0) ||
          (action.direction === Direction.Right &&
            index >= newState.currentUser.columns.length - 1)
        ) {
          break
        }

        // this performs the swapping, yes it looks pretty wild thanks to es6
        switch (action.direction) {
          case Direction.Left:
            [
              newState.currentUser.columns[index - 1],
              newState.currentUser.columns[index]
            ] = [
              newState.currentUser.columns[index],
              newState.currentUser.columns[index - 1]
            ]
            break
          case Direction.Right:
            [
              newState.currentUser.columns[index],
              newState.currentUser.columns[index + 1]
            ] = [
              newState.currentUser.columns[index + 1],
              newState.currentUser.columns[index]
            ]
            break
        }
      }
  }

  const newStateString = JSON.stringify(newState)
  if (newStateString === oldStateString) {
    return state // nothing changed, so return the old state
  } else {
    localStorage.setItem('auth', newStateString)
    return newState // return new state change and persist to 💾
  }
}

interface ColumnManager {
  add: (column: ColumnProps) => void
  remove: (columnID: string) => void
  move: (columnID: string, direction: Direction) => void
}

function AuthUserContextBuilder(): AuthContext {
  const startingData = JSON.parse(localStorage.getItem('auth') as string)
    ?? initialState

  const [authState, dispatch] = useReducer(authReducer, startingData)

  const setCurrentUser = (user: AuthedUser): Promise<void> => {
    return new Promise(resolve => {
      dispatch({ type: 'set-current-user', user })

      // do async stuff eventually
      resolve()
    })
  }

  const updateSettings = (settings: AppSettings) => {
    dispatch({ type: 'update-settings', settings })
  }

  const isAuthed = (): boolean => {
    return !!authState.currentUser.refreshKey.length
  }

  const setRedditAccount = (redditAccount: RedditUser) => {
    dispatch({ type: 'set-reddit-account', redditAccount })
  }

  const logout: () => Promise<void> = () => {
    return new Promise(resolve => {
      dispatch({ type: 'logout' })

      // eventually do more async cleanup
      resolve()
    })
  }

  const addUser = (user: AuthedUser) => {
    dispatch({ type: 'add-user', user })
  }

  // Column Manager
  const addColumn = (column: ColumnProps) => {
    dispatch({ type: 'add-column', column })
  }

  const removeColumn = (columnID: string) => {
    dispatch({ type: 'remove-column', columnID })
  }

  const moveColumn = (columnID: string, direction: Direction) => {
    dispatch({ type: 'move-column', columnID, direction })
  }

  return {
    ...authState,
    addUser,
    setCurrentUser,
    isAuthed,
    logout,
    setRedditAccount,
    updateSettings,
    columnManager: {
      add: addColumn,
      remove: removeColumn,
      move: moveColumn
    }
  }
}

function useAuth(): AuthContext {
  return useContext(Context)
}

function AuthProvider({ children }: ReactChildrenProps) {
  const authContext = AuthUserContextBuilder()
  return <Context.Provider value={authContext}>{children}</Context.Provider>
}

function PrivateRoute({ children, ...rest }: RouteProps) {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return auth?.isAuthed() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

export { Direction, useAuth, AuthProvider, PrivateRoute }
