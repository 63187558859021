import { useState } from 'react'
import { Comment, ReplyableContent, Submission } from 'snoowrap'
import styled from 'styled-components'

import Modal from '@components/Modal'

const Container = styled.div``

const Header = styled.div`
  display: flex;
  align-items: center;
`

interface AuthorProps {
  isSubmitter: boolean
}

const Author = styled.span<AuthorProps>`
  font-weight: bold;
  color: ${props => (props.isSubmitter ? 'blue' : '#111111')};
  font-size: 12px;
  margin-right: auto;
`

const ReplyButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  text-color: #989898;
  font-weight: 600;
  padding: 0;
  padding-bottom: 12px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const Body = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  border-left: 2px solid #cccccc;
  padding-left: 8px;
  margin-bottom: 8px;

  & > p {
    margin-top: 8px;
    word-break: break-word;
  }

  &:hover {
    border-left-color: rgba(205, 227, 249, 1);
  }

  & > div {
    margin-left: 8px;
  }
`

interface CommentViewProps {
  comment: Comment
  hideReplies?: boolean
}

function CommentView({ comment, hideReplies }: CommentViewProps) {
  const { author, is_submitter, body_html } = comment

  const [isReplying, setIsReplying] = useState(false)
  const [newComments, setNewComments] = useState<Comment[]>([])

  const onClickReply = () => {
    setIsReplying(true)
  }

  const dismissReplyModal = (
    newComment?: ReplyableContent<Comment | Submission>
  ) => {
    setIsReplying(false)
    if (newComment) {
      setNewComments([newComment as Comment, ...newComments])
    }
  }

  return (
    <Container>
      <Header>
        <Author isSubmitter={is_submitter}>{author.name}</Author>
      </Header>
      <Body>
        <p dangerouslySetInnerHTML={{ __html: body_html }} />
        <ReplyButton onClick={onClickReply}>reply</ReplyButton>
        {newComments.map(newComment => (
          <CommentView key={newComment.id} comment={newComment} />
        ))}
        {!hideReplies &&
          comment.replies.map(reply => (
            <CommentView key={reply.id} comment={reply} />
          ))}
      </Body>
      {isReplying && (
        <ReplyView content={comment} dismiss={dismissReplyModal} />
      )}
    </Container>
  )
}

interface ReplyViewProps {
  content: ReplyableContent<Comment | Submission>
  dismiss: (newComment?: ReplyableContent<Comment | Submission>) => void
}

const ReplyHeader = styled.h3`
  margin: 0;
`

function ReplyView({ content, dismiss }: ReplyViewProps) {
  const [replyText, setReplyText] = useState('')

  const onReplyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReplyText(e.target.value)
  }

  const onClickReply = () => {
    content.reply(replyText).then(newComment => {
      dismiss(newComment)
    })
  }

  return (
    <Modal onClick={() => dismiss()}>
      <ReplyHeader>Reply</ReplyHeader>
      <textarea
        placeholder="reply"
        value={replyText}
        onChange={onReplyChange}
      />
      <button onClick={onClickReply}>reply</button>
    </Modal>
  )
}

export { CommentView, ReplyView }
