interface OAuthUser {
  refreshKey: string
}

interface RedditAuthResponse {
  refreshkey?: string
  status?: string
}

class OAuth {
  start() {
    return new Promise((resolve: (user: RedditAuthResponse) => void) => {
      fetch('https://reditr.com/api/sync/?oauth=')
        .then(r => r.text())
        .then(identification => {
          // open a window to reddit's oauth
          const oAuthWin = window.open(
            'https://reditr.com/api/sync/?oauth=' + identification,
            'oauthwindow',
            'menubar=1,resizable=1'
          )

          // interval to check when window is closed so we can perform more actions
          const checkLoginInterval = setInterval(() => {
            // if window is closed, hit up reditr.com to see if oauth was successful
            if (
              !oAuthWin ||
              oAuthWin.closed ||
              typeof oAuthWin.closed == 'undefined'
            ) {
              clearInterval(checkLoginInterval)
              this.complete(identification).then(resolve)
            }
          }, 500)
        })
    })
  }

  complete(identification: string) {
    return fetch(
      `https://reditr.com/api/sync/?oauth=${identification}&revive=true`
    ).then(r => r.json())
  }

  getAccessToken(user: OAuthUser) {
    return new Promise((resolve, reject) => {
      if (!user.refreshKey) {
        reject()
      }

      fetch(
        `https://reditr.com/api/sync/?getAccessToken&oauth=${user.refreshKey}`
      )
        .then(r => r.json())
        .then(data => {
          resolve(data.accesstoken)
        })
    })
  }
}

export default new OAuth()
