import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  width: clamp(40%, 50%, 60%);
  background-color: #ffffff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`

function Modal({ children, ...rest }: ReactChildrenProps) {
  return (
    <Container {...rest}>
      <ModalContainer onClick={e => e.stopPropagation()}>{children}</ModalContainer>
    </Container>
  )
}

export default Modal
