import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { Submission, Listing, Comment, ReplyableContent, VoteableContent } from 'snoowrap'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import LineIcon from 'react-lineicons'
import { Link } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import { useSnoowrap } from '@contexts/SnoowrapContext'
import { useBanner, BannerType } from '@contexts/BannerContext'
import { onVoteMutation, VoteDirection } from '@mutations/onVote'
import { numberShortener } from '@utilities/formatters'
import MediaContainer from '@components/MediaContainer'
import { Column } from '@components/Column'

import { CommentView, ReplyView } from './components/CommentView'

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background-color: #efefef;
`

const SubmissionContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 100vh;
  padding-bottom: 5vh;
  padding-top: 5vh;
  box-sizing: border-box;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 8px;
`

const CloseButton = styled.div`
  display: flex;
  cursor: pointer;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 0;

  & > i {
    color: #000000;
    font-size: 14px;
    font-weight: bolder;
  }
`

const Title = styled(Link)`
  font-weight: bold;
  font-size: 16px;
  flex-grow: 1;
  padding-right: 16px;
  margin-top: 0;
  color: #000000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const SelfText = styled.p`
  padding: 0 16px;
  font-size: 16px;
  color: #000000;
`

const SubmissionCard = styled.div`
  width: clamp(40%, 75%, 90%);
  min-height: 30vh;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  overflow: hidden;
`

const CommentsContainer = styled.div`
  padding: 16px;
  border-top: 1px solid #eeeeee;
  flex-grow: 1;
`

const EmptyComments = styled.div`
  color: #434343;
  width: 100%;
  text-align: center;
  font-size: 14px;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > i {
    font-size: 24px;
    color: #3c3c3c;
    animation: ${rotate} 1s linear infinite;
  }
`

const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 6px 16px;
`

const Pill = styled.div`
  padding: 4px 6px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  height: 10px;
  font-size: 12px;
  border-radius: 6px;
  margin-right: 3px;
  margin-bottom: 2px;
  line-height: 10px;
  font-weight: bold;
`

const NSFWPill = styled(Pill)`
  background-color: pink;
  border-color: #ffb0be;
  color: #ff4747;
`

interface SubmissionURLParams {
  subreddit: string
  id: string
}

function SubmissionView() {
  const { subreddit, id } = useParams<SubmissionURLParams>()

  return (
    <Container>
      <Column
        id={uuidV4()}
        inSubmissionView={true}
        subreddit={subreddit}
        activeSubmissionID={id}
      />
      <SubmissionContent>
        <SubmissionCardView submissionID={id} />
      </SubmissionContent>
    </Container>
  )
}

const ReplyButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  text-color: #989898;
  font-weight: 600;
  padding: 0;
  padding-bottom: 12px;
  margin-left: 16px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const VoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 8px;
`

interface VoteProps {
  active: boolean | null
}

const UpVote = styled.span<VoteProps>`
  background-color: ${props =>
    props.active ? 'rgba(255, 139, 90, 0.1)' : 'transparent'};
  width: 32px;
  height: 32px;
  color: ${props => (props.active ? 'red' : 'black')};
  margin-bottom: 6px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all ease-in 0.2s;
  cursor: pointer;
`

const Score = styled.span`
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: 500;
`

const DownVote = styled.span<VoteProps>`
  background-color: ${props =>
    props.active ? 'rgba(38, 37, 123, 0.05)' : 'transparent'};
  color: ${props => (props.active ? 'blue' : 'black')};
  width: 32px;
  height: 32px;
  font-size: 16px;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all ease-in 0.2s;
  cursor: pointer;
`

interface SubmissionCardProps {
  submissionID: string
  maxComments?: number
}

export function SubmissionCardView({
  submissionID,
  maxComments
}: SubmissionCardProps) {
  const snoowrap = useSnoowrap()
  const history = useHistory()
  const queryClient = useQueryClient()
  const banner = useBanner()

  const [isReplying, setIsReplying] = useState(false)
  const [newComments, setNewComments] = useState<Comment[]>([])

  const onClickReply = () => {
    setIsReplying(true)
  }

  const dismissReplyModal = (
    newComment?: ReplyableContent<Comment | Submission>
  ) => {
    setIsReplying(false)

    if (newComment) {
      setNewComments([newComment as Comment, ...newComments])
    }
  }

  const exit = () => {
    history.push('/')
  }

  const { isLoading, isFetching, data } = useQuery<Submission>(
    ['submission', submissionID],
    () => {
      return snoowrap.getClient()!.getSubmission(submissionID).fetch()
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!snoowrap.getClient()
    }
  )

  let comments: Listing<Comment> | Comment[] | undefined = data?.comments
  if (comments && maxComments) {
    comments = comments.slice(0, maxComments)
  }

  const { over_18 } = data || {}
  const hasPills = over_18 // add more here as we add more pills

  const warmSubmissionCache = () => {
    queryClient.setQueryData(['submission', submissionID], data)
    queryClient.invalidateQueries(['submission', submissionID])
  }

  const standardErrorHandling = (e: any) => {
    switch (e.statusCode) {
      case 400:
        banner.showBanner({
          message: 'Unable to vote on this post.',
          type: BannerType.error
        })
        break
      case 429:
        banner.showBanner({
          message: 'You have been rate limited, try again in a few seconds.',
          type: BannerType.error
        })
        break
      default:
        banner.showBanner({
          message: 'Something went wrong with your request.',
          type: BannerType.error
        })
        break
    }

    // reset to older state
    queryClient.setQueryData(['submission', submissionID], data)
  }

  const didClickUpVote = useMutation(
    (likes: boolean | null) => {
      const submission = snoowrap.getClient()!.getSubmission(submissionID)
      return likes === true ? submission.unvote() : submission.upvote()
    },
    {
      // When mutate is called:
      onMutate: onVoteMutation({
        direction: VoteDirection.up,
        content: queryClient.getQueryData(['submission', submissionID])!,
        preVote: async () => {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries(['submission', submissionID])
        },
        postVote: (postVoteSubmission: VoteableContent<Submission>) => {
          // Optimistically update to the new value
          queryClient.setQueryData(['submission', submissionID], postVoteSubmission)
        }
      }),
      onError: standardErrorHandling
    }
  )

  const didClickDownVote = useMutation(
    (likes: boolean | null) => {
      const submission = snoowrap.getClient()!.getSubmission(submissionID)
      return likes === false ? submission.unvote() : submission.downvote()
    },
    {
      // When mutate is called:
      onMutate: onVoteMutation({
        direction: VoteDirection.down,
        content: queryClient.getQueryData(['submission', submissionID])!,
        preVote: async () => {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          queryClient.cancelQueries(['submission', submissionID])
        },
        postVote: (postVoteSubmission: VoteableContent<Submission>) => {
          // Optimistically update to the new value
          queryClient.setQueryData(['submission', submissionID], postVoteSubmission)
        }
      }),
      onError: standardErrorHandling
    }
  )

  const { likes, score } = data ?? { likes: null, score: 0 }

  return (
    <SubmissionCard>
      <Header>
        <VoteContainer>
          <UpVote
            active={likes}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              didClickUpVote.mutate(likes)
            }}
          >
            <LineIcon name="chevron-up" size="lg" />
          </UpVote>
          <Score>{numberShortener(score, 1)}</Score>
          <DownVote
            active={likes === false}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              didClickDownVote.mutate(likes)
            }}
          >
            <LineIcon name="chevron-down" size="lg" />
          </DownVote>
        </VoteContainer>
        <Title
          title={data?.title}
          to={data?.permalink || ''}
          onClick={warmSubmissionCache}
        >
          {data?.title}
        </Title>
        <CloseButton onClick={exit}>
          <LineIcon name="close" />
        </CloseButton>
      </Header>
      {hasPills && (
        <PillsContainer>{over_18 && <NSFWPill>NSFW</NSFWPill>}</PillsContainer>
      )}
      {data?.selftext_html && (
        <SelfText
          dangerouslySetInnerHTML={{ __html: data?.selftext_html }}
        ></SelfText>
      )}
      {data && <MediaContainer {...data} />}
      <ReplyButton onClick={onClickReply}>reply</ReplyButton>
      <CommentsContainer>
        {newComments.map(newComment => (
          <CommentView key={newComment.id} comment={newComment} />
        ))}
        {comments?.map(comment => (
          <CommentView
            key={comment.id}
            comment={comment}
            hideReplies={!!maxComments}
          />
        ))}
        {isFetching && !data?.comments && (
          <LoadingContainer>
            <LineIcon name="spinner-solid" />
          </LoadingContainer>
        )}
        {!isFetching && data?.comments.length === 0 && (
          <EmptyComments>No comments.</EmptyComments>
        )}
      </CommentsContainer>
      {isReplying && data && (
        <ReplyView content={data} dismiss={dismissReplyModal} />
      )}
    </SubmissionCard>
  )
}

export default SubmissionView
