import styled, { keyframes } from 'styled-components'
import { useBanner, Message, BannerType } from '@contexts/BannerContext'
import { useState } from 'react'
import LineIcon from 'react-lineicons'

interface BannerContainerProps {
  textColor: string
  backgroundColor: string
  isMounted: boolean
}

const slideIn = keyframes`
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 10px;
    opacity: 1;
  }
`

const slideOut = keyframes`
  from {
    top: 10px;
    opacity: 1;
  }

  to {
    top: -100px;
    opacity: 0;
  }
`

const BannerContainer = styled.div<BannerContainerProps>`
  display: flex;
  align-items: center;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  width: 260px;
  padding: 16px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  left: calc(50% - 120px);
  user-select: none;
  margin-right: 8px;
  animation: ${props => (props.isMounted ? slideIn : slideOut)} 0.5s ease-in
    forwards;
`

interface CloseButtonProps {
  color: string
}

const CloseButton = styled.div<CloseButtonProps>`
  cursor: pointer;
  color: ${props => props.color};
  margin-left: auto;
  font-size: 12px;
  font-weight: bold;
`

interface BannerProps {
  banner: Message
}

let closeBannerTimeout: number | undefined

function Banner({ banner }: BannerProps) {
  const { id, message, type, timeout } = banner
  const [mounted, setMounted] = useState(true)

  closeBannerTimeout = window.setTimeout(() => setMounted(false), 0.9 * timeout)

  const bannerContext = useBanner()
  const closeBanner = () => {
    clearTimeout(closeBannerTimeout)
    setMounted(false)
    setTimeout(() => bannerContext.closeBanner(id), 500)
  }

  var textColor = '#000000'
  var backgroundColor = '#eeeeee'
  switch (type) {
    case BannerType.warning:
      textColor = '#000000'
      backgroundColor = '#EBC242'
      break
    case BannerType.error:
      textColor = '#ffffff'
      backgroundColor = '#B33C1C'
      break
    case BannerType.success:
      textColor = '#ffffff'
      backgroundColor = 'green'
      break
    default:
      break
  }

  return (
    <BannerContainer
      isMounted={mounted}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      {message}
      <CloseButton onClick={closeBanner} color={textColor}>
        <LineIcon name="close" />
      </CloseButton>
    </BannerContainer>
  )
}

export default Banner
