import { useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

import { useApp } from '@contexts/AppContextProvider'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { useAuth } from '@contexts/AuthContext'

import Modal from '@components/Modal'
import { ColumnProps } from '@components/Column'

enum ColumnType {
  Subreddit = 'Subreddit',
  Search = 'Search',
  User = 'User'
}

function AddColumn() {
  const app = useApp()
  const snoowrap = useSnoowrap()
  const auth = useAuth()

  const onClickBackground = () => {
    app.showAddColumn(false)
  }

  const [columnType, setColumnType] = useState(ColumnType.Subreddit)
  const [textValue, setTextValue] = useState('')

  const getPlaceholder = (columnType: ColumnType) => {
    switch (columnType) {
      case ColumnType.Subreddit:
        return 'subreddit'
      case ColumnType.Search:
        return 'search query'
      case ColumnType.User:
        return 'username'
    }
  }

  const onAddColumn = () => {
    // create base column, every column needs an id
    const column: ColumnProps = {
      id: uuidV4()
    }
    switch (columnType) {
      case ColumnType.Subreddit:
        snoowrap.getClient()?.getSubreddit(textValue).subscribe()
        auth.columnManager.add({ subreddit: textValue, ...column })
        break
      case ColumnType.Search:
        auth.columnManager.add({ searchQuery: textValue, ...column })
        break
      case ColumnType.User:
        auth.columnManager.add({ user: textValue, ...column })
        break
    }
    app.showAddColumn(false)
  }

  if (app.isAddingColumn) {
    return (
      <Modal onClick={onClickBackground}>
        <select
          value={columnType}
          onChange={e => {
            setColumnType(ColumnType[e.target.value as keyof typeof ColumnType])
          }}
        >
          {Object.values(ColumnType).map(type => (
            <option>{type}</option>
          ))}
        </select>
        <input
          value={textValue}
          onChange={e => setTextValue(e.target.value)}
          placeholder={getPlaceholder(columnType)}
        />
        <button onClick={onAddColumn}>Add Column</button>
      </Modal>
    )
  }

  return null
}

export default AddColumn
