import { useRef } from 'react'
import { useInfiniteQuery } from 'react-query'
import { Listing, Submission, Subreddit } from 'snoowrap'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { VirtualList, VirtualListHandle } from '@components/VirtualList'

import { useSnoowrap } from '@contexts/SnoowrapContext'

import { SubmissionCardView } from '@screens/SubmissionView'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #efefef;
`

const StyledVirtualList = styled(VirtualList)`
  margin: 0;
  overflow: auto;
`

const Header = styled.div`
  width: clamp(40%, 75%, 90%);
  overflow: hidden;
  margin: 0 auto;
  min-height: 25vh;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  margin-top: 5vh;
  flex-shrink: 0;

  & > img {
    width: 100%;
  }
`

const SubredditInfo = styled.div`
  padding: 16px;
`

const Submissions = styled.div`
  margin-top: 5vh;
`

const SubmissionContainer = styled.div`
  margin-bottom: 5vh;
`

interface StreamProps {
  subreddit: string
}

function Stream() {
  const snoowrap = useSnoowrap()
  const { subreddit } = useParams<StreamProps>()

  let listing: Listing<Submission> | undefined
  const { fetchNextPage, isLoading } = useInfiniteQuery<Listing<Submission>>(
    ['subreddits', subreddit, 'submissions'],
    ({ pageParam }) => {
      // paginate if we already have a listing
      if (pageParam && listing) {
        return listing.fetchMore({ amount: 25 })
      } else {
        return snoowrap.getClient()!.getSubreddit(subreddit).getHot()
      }
    },
    {
      getNextPageParam: lastPage => {
        // set listing with new submissions fetched
        listing = lastPage
        return !lastPage.isFinished
      },
      keepPreviousData: true,
      enabled: snoowrap.getClient() !== null,
      refetchOnWindowFocus: false,
      structuralSharing: false
    }
  )

  const virtualRef = useRef<VirtualListHandle>(null)
  return (
    <Container>
      {listing && (
        <StyledVirtualList
          ref={virtualRef}
          rows={listing}
          hasMore={!listing.isFinished}
          loadMore={() => {
            fetchNextPage()
          }}
          virtualListOverrides={{
            paddingStart: 64
          }}
          // header={() => (
          //   <Header>
          //     {subredditInfo?.banner_background_image && (
          //       <img src={subredditInfo?.banner_background_image} />
          //     )}
          //     <SubredditInfo>
          //       <h1>r/{subreddit}</h1>
          //       {subredditInfo?.description_html && (
          //         <p
          //           dangerouslySetInnerHTML={{
          //             __html: subredditInfo?.public_description_html
          //           }}
          //         />
          //       )}
          //     </SubredditInfo>
          //   </Header>
          // )}
        >
          {({ virtualRow }) => {
            const submission = listing![virtualRow.index]
            return (
              <SubmissionContainer key={submission.id}>
                <SubmissionCardView
                  submissionID={submission.id}
                  maxComments={2}
                />
              </SubmissionContainer>
            )
          }}
        </StyledVirtualList>
      )}
    </Container>
  )
}

export default Stream
