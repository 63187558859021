import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import LineIcon from 'react-lineicons'
import { Trans } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import { useSnoowrap } from '@contexts/SnoowrapContext'
import { useAuth } from '@contexts/AuthContext'
import { useMySubreddits } from '@hooks/snoowrapHooks'
import OAuth from '@api/oauth'

import { ColumnProps } from '@components/Column'

import logo from '@images/logo.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #3f4245;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  & > h1 {
    color: #ffffff;
    font-size: 72px;
    font-weight: bold;
  }
`

const Logo = styled.img`
  width: 240px;
  margin-right: 20px;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const LoginButton = styled.button`
  border: none;
  padding: 15px 30px;
  display: block;
  background-color: #e33e5c;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;

    & > i {
      animation: ${rotate} 2s linear infinite;
    }
  }

  & > i {
    font-weight: bold;
    font-size: 18px;
    margin-left: 6px;
  }
`

enum LoginState {
  start,
  loggingIn,
  success,
  failed
}

function Login() {
  const history = useHistory()
  const snoowrap = useSnoowrap()
  const auth = useAuth()

  const [loginStatus, setLoginStatus] = useState(LoginState.start)
  const [buttonText, setButtonText] = useState('Login with Reddit')

  // if already logged in you can't login again
  useEffect(() => {
    if (auth.isAuthed()) {
      history.push('/')
    }
  })

  const startOAuth = async () => {
    if (loginStatus !== LoginState.start && loginStatus !== LoginState.failed) {
      return
    }
    setLoginStatus(LoginState.loggingIn)
    setButtonText('Waiting...')
    const { refreshkey } = await OAuth.start()
    if (refreshkey != null) {
      setLoginStatus(LoginState.success)
      setButtonText('Success!')

      // TODO: Move this to some app warmup helper
      const client = snoowrap.setClient(refreshkey)

      // we need to wait a second so that we don't get rate limited
      setTimeout(async () => {
        // get account subreddits to populate subreddits
        const subreddits = client!.getSubscriptions().fetchAll()

        const columns: ColumnProps[] = await subreddits.map(subreddit => {
          return { id: uuidV4(), subreddit: subreddit.display_name }
        })

        await auth.setCurrentUser({
          refreshKey: refreshkey,
          settings: { columnWidth: 500 },
          columns
        })

        // redirect
        history.push('/')
      }, 1000)
    } else {
      setLoginStatus(LoginState.failed)
      setButtonText('Failed to login. Try again.')
    }
  }

  const showRedditIcon =
    loginStatus === LoginState.start || loginStatus === LoginState.failed
  const showLoadingIcon = loginStatus === LoginState.loggingIn
  const showSuccessIcon = loginStatus === LoginState.success

  return (
    <Container>
      <Header>
        <Logo alt="Reditr Logo, represents an alien spaceship" src={logo} />
        <h1>Reditr</h1>
      </Header>
      <LoginButton disabled={showLoadingIcon} onClick={startOAuth}>
        <span>
          <Trans>{buttonText}</Trans>
        </span>
        {showRedditIcon && <LineIcon name="reddit" size="lg" />}
        {showLoadingIcon && (
          <LineIcon name="spinner-solid" effect="spin" size="lg" />
        )}
        {showSuccessIcon && <LineIcon name="checkmark" size="lg" />}
      </LoginButton>
    </Container>
  )
}

export default Login
