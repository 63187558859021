import { useState, createContext, useContext, useEffect } from 'react'
import Snoowrap from 'snoowrap'

import { useAuth } from '@contexts/AuthContext'
import AddAccount from '@screens/ManageUsers/components/AddAccount'

export enum RedditStatus {
  up,
  down
}

interface SnoowrapClientContext {
  getClient: () => Snoowrap | null
  setClient: (refreshKey: string) => Snoowrap | null
  clear: () => void
  status: RedditStatus,
  setRedditStatus: (status: RedditStatus) => void
}

const SnoowrapContext = createContext<SnoowrapClientContext>(
  {} as SnoowrapClientContext
)

const guestDeviceId = Date.now() + ':rdr:' + Math.floor(Math.random() * 10000)
const appCredentials = {
  userAgent: 'web:reditr-web:dev-local',
  clientId: 'P3OV-qUrp57Z8A',
  clientSecret: 'oZbplkCqnRj2mxVAWcwFEniatl8'
}

let count = 0

function SnoowrapContextBuilder(
  refreshKey: string 
): SnoowrapClientContext {
  const [status, setStatus] = useState(RedditStatus.up)
  const [fetchedClient, setFetchedClient] = useState<Boolean>(false)
  const [client, setClient] = useState<Snoowrap | null>(null)

  const setSnoowrapClient = (refreshToken: string) => {
    if (refreshToken === '') {
      Snoowrap
        .fromApplicationOnlyAuth({
          ...appCredentials,
          deviceId: guestDeviceId,
          permanent: false,
          grantType: 'https://oauth.reddit.com/grants/installed_client'
        } as any)
        .then(setClient)
      return null
    } else {
      const newClient = new Snoowrap({
        refreshToken,
        ...appCredentials
      })
      newClient.config({ debug: true, warnings: true })
      setClient(newClient)
      return newClient
    }
  }

  if (!fetchedClient) {
    setSnoowrapClient(refreshKey)
    setFetchedClient(true)
  }

  const clear = () => {
    setClient(null)
  }

  const getClient = (): Snoowrap | null => {
    if (client && client.ratelimitRemaining === 0) {
      // banner.showBanner({
      //   message: "You've been rate limited.",
      //   type: BannerType.error
      // })
    }
    return client
  }

  const setRedditStatus = (status: RedditStatus) => {
    setStatus(status)
  }

  return { getClient, setClient: setSnoowrapClient, clear, status, setRedditStatus }
}

export function useSnoowrap(): SnoowrapClientContext {
  return useContext(SnoowrapContext)
}

export function SnoowrapProvider({ children }: ReactChildrenProps) {
  const auth = useAuth()
  const snoowrapContext = SnoowrapContextBuilder(auth.currentUser.refreshKey)

  useEffect(() => {
    console.log('snoocontextchanged')
      const client = snoowrapContext.getClient()
      if (!client || auth.currentUser?.redditAccount) return
      // reddit health check
      client
        .getMe()
        .then(account => {
          auth.setRedditAccount(account)
          // snoowrapContext.setRedditStatus(RedditStatus.up)
        })
        .catch(() => {
          // snoowrapContext.setRedditStatus(RedditStatus.down)
        })

  }, [auth, snoowrapContext])
  return (
    <SnoowrapContext.Provider value={snoowrapContext}>
      {children}
    </SnoowrapContext.Provider>
  )
}
