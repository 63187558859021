import styled from 'styled-components'

import { useApp } from '@contexts/AppContextProvider'
import { useAuth } from '@contexts/AuthContext'

import Modal from '@components/Modal'

function Settings() {
  const app = useApp()
  const auth = useAuth()

  const setColumnWidth = (width: number) => () => {
    if (auth.currentUser) {
      let settings = { ...auth.currentUser.settings }
      settings.columnWidth = width
      auth.updateSettings(settings)
    }
  }

  const onClickBackground = () => {
    app.showSettings(false)
  }

  if (app.isShowingSettings) {
    return (
      <Modal onClick={onClickBackground}>
        <button onClick={setColumnWidth(240)}>Thin</button>
        <button onClick={setColumnWidth(310)}>Default</button>
        <button onClick={setColumnWidth(400)}>Wide</button>
      </Modal>
    )
  }

  return null
}

export default Settings
