import { useHistory } from 'react-router'

import oauth from '@api/oauth'
import { useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { useEffect } from 'react'

import { ColumnProps } from '@components/Column'

function AddAccount() {
  const auth = useAuth()
  const snoowrap = useSnoowrap()
  const history = useHistory()

  const startOAuth = async () => {
    const { refreshkey } = await oauth.start()
    if (refreshkey != null) {
      // TODO: Move this to some app warmup helper
      const client = snoowrap.setClient(refreshkey)

      // we need to wait a second so that we don't get rate limited
      setTimeout(async () => {
        // get account subreddits to populate subreddits
        const subreddits = client!.getSubscriptions().fetchAll()

        const columns = await subreddits.map(subreddit => {
          return { subreddit: subreddit.display_name } as ColumnProps
        })

        await auth.addUser({
          refreshKey: refreshkey,
          settings: { columnWidth: 500 },
          columns
        })

        // redirect
        history.push('/')
      }, 1000)
    } else {
    }
  }

  useEffect(() => {
    startOAuth()
  }, [])

  return null
}

export default AddAccount
