import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { SubmitLinkOptions, SubmitSelfPostOptions } from 'snoowrap'
import { useHistory } from 'react-router-dom'

import { useBanner, BannerType } from '@contexts/BannerContext'
import { useApp } from '@contexts/AppContextProvider'
import { useSnoowrap } from '@contexts/SnoowrapContext'

import { useQueryParams } from '@hooks/useQueryParams'

import Modal from '@components/Modal'


const Title = styled.h3`
  margin: 0;
`

const FormContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 16px;
  flex-grow: 1;
`

const SubredditInput = styled.input`
  width: 100%;
  font-size: 18px;
  border: none;
  outline: 0;
  line-height: 24px;
`

const TitleInput = styled.input`
  width: 100%;
  font-size: 24px;
  border: none;
  border-bottom: 1px solid #dddddd;
  outline: 0;
  line-height: 48px;
  font-weight: 500;
`

const ContentInput = styled.textarea`
  border: none;
  margin-top: 12px;
  font-size: 16px;
  min-height: 20vh;
  outline: none;
  width: 100%;
  resize: none;
`

const RaisedButton = styled.button`
  border: none;
  padding: 15px 30px;
  display: block;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 14px;
  color: #000000;

  &:hover {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
`

const PostButton = styled(RaisedButton)`
  background-color: rgb(37, 146, 255);
  color: #ffffff;
`

const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`

const LinkInput = styled.input`
  width: 100%;
  font-size: 20px;
  border: none;
  outline: 0;
  line-height: 48px;
  margin-bottom: 16px;
`

const PostTypeContainer = styled.div`
  display: flex;
  margin-top: 8px;
`

interface ButtonProps {
  active: boolean
}
const Button = styled.button<ButtonProps>`
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: ${props => props.active ? 'blue' : '#000000'};
`

enum PostType {
  selfText,
  link
}

function CreatePost() {
  const app = useApp()
  const queryParams = useQueryParams()
  const snoowrap = useSnoowrap()
  const banner = useBanner()
  const history = useHistory()

  const [postType, setPostType] = useState(PostType.selfText)

  const [formData, setFormData] = useState<SubmitSelfPostOptions | SubmitLinkOptions>(
    {} as SubmitSelfPostOptions
  )

  // deep link example (not really needed but nice for future).
  // useful for testing at least
  useEffect(() => {
    if (queryParams.get('action') === 'create-post') {
      app.showCreatePost(true)
    }
  }, [app, queryParams])

  const onClickBackground = (e: any) => {
    if (e.target.id === 'background') {
      app.showCreatePost(false)
    }
  }

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    const { title, subredditName } = formData as SubmitSelfPostOptions
    const { url } = formData as SubmitLinkOptions
    if (title && subredditName && postType === PostType.selfText) {
      snoowrap
        .getClient()!
        .submitSelfpost(formData)
        .then(submission => submission.fetch())
        .then(submission => {
          banner.showBanner({
            message: 'Submitted a post!',
            type: BannerType.success
          })
          app.showCreatePost(false)
          history.push(submission.permalink)
        })
    } else if (title && subredditName && url && postType === PostType.link) {
      snoowrap
        .getClient()!
        .submitLink(formData as SubmitLinkOptions)
        .then(submission => submission.fetch())
        .then(submission => {
          banner.showBanner({
            message: 'Submitted a post!',
            type: BannerType.success
          })
          app.showCreatePost(false)
          history.push(submission.permalink)
        })
    }
  }

  if (app.isShowingCreatePost) {
    return (
      <Modal id="background" onClick={onClickBackground}>
          <Title>Create Post</Title>
          <PostTypeContainer>
            <Button active={postType === PostType.selfText} onClick={() => setPostType(PostType.selfText)}>Text</Button>
            <Button active={postType === PostType.link} onClick={() => setPostType(PostType.link)}>Link</Button>
          </PostTypeContainer>
          <FormContainer onSubmit={onSubmit}>
            <SubredditInput
              name="subredditName"
              placeholder="/r/"
              onChange={handleChange}
            />
            <TitleInput
              type="text"
              name="title"
              placeholder="Title"
              onChange={handleChange}
            />
            {postType === PostType.link && (
              <LinkInput
                name="url"
                placeholder="URL"
                onChange={handleChange}
              />
            )}
            {postType === PostType.selfText && (
              <ContentInput
                name="text"
                placeholder="Submission Content"
                onChange={handleChange}
              />
            )}
            <FormButtons>
              <RaisedButton onClick={() => app.showCreatePost(false)}>
                Cancel
              </RaisedButton>
              <PostButton type="submit">Post</PostButton>
            </FormButtons>
          </FormContainer>
        </Modal>
    )
  }
  return null
}

export default CreatePost
