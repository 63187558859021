import styled from 'styled-components'

const ContentContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > iframe {
    width: 100%;
    height: 500px;
  }

  & > video {
    width: 100%;
    max-height: 500px;
  }

  & > img {
    max-width: 100%;
  }
`

// TODO: Handle these anys
interface MediaContainerProps {
  secure_media_embed: any
  secure_media: any
  preview: any
  post_hint: string
  url: string
  style?: any
  hideVideo?: boolean
  onLoad?: () => void
}

// TODO: If hiding video, show picture of video
function MediaContainer({
  secure_media_embed,
  secure_media,
  preview,
  post_hint,
  url,
  style,
  hideVideo,
  onLoad
}: MediaContainerProps) {
  const getEmbeddedMedia = (): string | undefined => {
    if (secure_media_embed) {
      return secure_media_embed.content
    }
  }

  const getRedditVideoURL = (): string | undefined => {
    const anyPreview = preview as any
    if (anyPreview?.reddit_video_preview) {
      return anyPreview.reddit_video_preview.fallback_url
    }
    if (secure_media?.reddit_video) {
      return secure_media.reddit_video.fallback_url
    }
  }

  const mediaHTML = getEmbeddedMedia()
  const redditVideoURL = getRedditVideoURL()

  if (mediaHTML && !hideVideo) {
    return (
      <ContentContainer
        style={style}
        dangerouslySetInnerHTML={{ __html: mediaHTML }}
      />
    )
  }

  if (redditVideoURL && !hideVideo) {
    return (
      <ContentContainer style={style}>
        <video key={redditVideoURL} controls>
          <source src={redditVideoURL} type="video/mp4" />
        </video>
      </ContentContainer>
    )
  }

  if (post_hint === 'image') {
    return (
      <ContentContainer style={style}>
        <img key={url} src={url} onLoad={onLoad} />
      </ContentContainer>
    )
  }

  return null
}

export default MediaContainer
