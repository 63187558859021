import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import styled from 'styled-components'

import { PrivateRoute } from '@contexts/AuthContext'
import { AppContextProvider } from '@contexts/AppContextProvider'

import Home from '@screens/Home'
import Login from '@screens/Login'
import Logout from '@screens/Logout'
import SubmissionView from '@screens/SubmissionView'
import Stream from '@screens/Stream'
import CreatePost from '@screens/CreatePost'
import Settings from '@screens/Settings'

import RedditBanner from '@components/RedditBanner'
import Sidebar from '@components/Sidebar'
import Banner from '@components/Banner'
import { BannerContext } from './contexts/BannerContext'
import AddAccount from '@screens/ManageUsers/components/AddAccount'
import AddColumn from '@screens/AddColumn'

const queryClient = new QueryClient()

const RootContainer = styled.div`
  overflow: hidden;
  height: 100vh;
  background-color: #efefef;
`

const MainContainer = styled.div`
  display: flex;
`

const ContentContainer = styled.div`
  overflow: hidden;
  position: relative;
  flex-grow: 1;
`

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Router>
          <RootContainer>
            {/* Reddit is Down Banner */}
            <RedditBanner />
            <BannerContext.Consumer>
              {context =>
                context.currentBanner && (
                  <Banner banner={context.currentBanner} />
                )
              }
            </BannerContext.Consumer>
            <Switch>
              {/* Account Management */}
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logout" component={Logout} />
              {/* Main App View/Routes */}
              <Route path="/">
                <MainContainer>
                  <Sidebar />
                  <ContentContainer>
                    <Route path="/">
                      <Home />
                    </Route>
                    <Route path="/r/:subreddit/comments/:id">
                      <SubmissionView />
                    </Route>
                    <Route exact path="/r/:subreddit">
                      <Stream />
                    </Route>
                  </ContentContainer>
                </MainContainer>
              </Route>
            </Switch>
          </RootContainer>

          {/* Modals */}
          <CreatePost />
          <Settings />
          <AddColumn />
        </Router>
      </AppContextProvider>
    </QueryClientProvider>
  )
}

export default App
