import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Submission, VoteableContent } from 'snoowrap'
import LineIcon from 'react-lineicons'
import { useMutation, useQueryClient } from 'react-query'

import { useSnoowrap } from '@contexts/SnoowrapContext'
import { useBanner, BannerType } from '@contexts/BannerContext'
import { onVoteMutation, VoteDirection } from '@mutations/onVote'

import { numberShortener } from '@utilities/formatters'

import MediaContainer from '@components/MediaContainer'

interface ContainerProps {
  selected: boolean
}

const Container = styled(Link)<ContainerProps>`
  display: flex;
  min-height: 100px;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding: 8px;
  box-sizing: border-box;
  text-decoration: none;
  color: #000000;
  background-color: ${props =>
    props.selected ? 'rgba(205, 227, 249, 0.25)' : 'transparent'};

  &:hover {
    background-color: rgba(205, 227, 249, 0.25);
  }
`

const Title = styled.span`
  font-weight: 600;
  word-wrap: anywhere;
`

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const PostMetadata = styled.div`
  margin-top: auto;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
`

const VoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 16%;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  padding-right: 8px;
`

interface VoteProps {
  active: boolean | null
}

const UpVote = styled.span<VoteProps>`
  background-color: ${props =>
    props.active ? 'rgba(255, 139, 90, 0.1)' : 'transparent'};
  width: 32px;
  height: 32px;
  color: ${props => (props.active ? 'red' : 'black')};
  margin-bottom: 6px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all ease-in 0.2s;
`

const Score = styled.span`
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: 500;
`

const DownVote = styled.span<VoteProps>`
  background-color: ${props =>
    props.active ? 'rgba(38, 37, 123, 0.05)' : 'transparent'};
  color: ${props => (props.active ? 'blue' : 'black')};
  width: 32px;
  height: 32px;
  font-size: 16px;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all ease-in 0.2s;
`

const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
`

const Pill = styled.div`
  padding: 4px 6px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  height: 10px;
  font-size: 12px;
  border-radius: 6px;
  margin-right: 3px;
  margin-bottom: 2px;
  line-height: 10px;
  font-weight: bold;
`

const NSFWPill = styled(Pill)`
  background-color: pink;
  border-color: #ffb0be;
  color: #ff4747;
`

interface SubmissionRowProps {
  submission: Submission
  selected: boolean
  onLoadMedia?: () => void
}

function SubmissionRow({
  submission,
  selected,
  onLoadMedia
}: SubmissionRowProps) {
  const { id } = submission

  const queryClient = useQueryClient()
  const snoowrap = useSnoowrap()
  const banner = useBanner()

  const standardErrorHandling = (e: any) => {
    switch (e.statusCode) {
      case 400:
        banner.showBanner({
          message: 'Unable to vote on this post.',
          type: BannerType.error
        })
        break
      case 429:
        banner.showBanner({
          message: 'You have been rate limited, try again in a few seconds.',
          type: BannerType.error
        })
        break
      default:
        banner.showBanner({
          message: 'Something went wrong with your request.',
          type: BannerType.error
        })
        break
    }

    // reset to older state
    queryClient.setQueryData(['submission', id], submission)
  }

  const didClickUpVote = useMutation(
    (likes: boolean | null) => {
      const submission = snoowrap.getClient()!.getSubmission(id)
      return likes === true ? submission.unvote() : submission.upvote()
    },
    {
      // When mutate is called:
      onMutate: onVoteMutation({
        direction: VoteDirection.up,
        content: queryClient.getQueryData(['submission', id]) ?? submission,
        preVote: async () => {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries(['submission', id])
        },
        postVote: (postVoteSubmission: VoteableContent<Submission>) => {
          // Optimistically update to the new value
          queryClient.setQueryData(['submission', id], postVoteSubmission)
        }
      }),
      onError: standardErrorHandling
    }
  )

  const didClickDownVote = useMutation(
    (likes: boolean | null) => {
      const submission = snoowrap.getClient()!.getSubmission(id)
      return likes === false ? submission.unvote() : submission.downvote()
    },
    {
      // When mutate is called:
      onMutate: onVoteMutation({
        direction: VoteDirection.down,
        content: queryClient.getQueryData(['submission', id]) ?? submission,
        preVote: async () => {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          queryClient.cancelQueries(['submission', id])
        },
        postVote: (postVoteSubmission: VoteableContent<Submission>) => {
          // Optimistically update to the new value
          queryClient.setQueryData(['submission', id], postVoteSubmission)
        }
      }),
      onError: standardErrorHandling
    }
  )

  const {
    permalink,
    likes,
    title,
    author,
    num_comments,
    score,
    over_18,
    secure_media_embed,
    secure_media,
    preview,
    post_hint,
    url
  }: Submission = queryClient.getQueryData(['submission', id]) ?? submission

  const hasPills = over_18

  const warmSubmissionCache = () => {
    queryClient.setQueryData(['submission', id], submission)
    queryClient.invalidateQueries(['submission', id])
  }

  return (
    <Container
      to={permalink}
      onClick={warmSubmissionCache}
      key={id}
      selected={selected}
    >
      <VoteContainer>
        <UpVote
          active={likes}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            didClickUpVote.mutate(likes)
          }}
        >
          <LineIcon name="chevron-up" size="lg" />
        </UpVote>
        <Score>{numberShortener(score, 1)}</Score>
        <DownVote
          active={likes === false}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            didClickDownVote.mutate(likes)
          }}
        >
          <LineIcon name="chevron-down" size="lg" />
        </DownVote>
      </VoteContainer>
      <PostContainer>
        <Title>{title}</Title>
        <PostMetadata>
          <span>{author.name}</span>
          <span>{num_comments} comments</span>
        </PostMetadata>
        {hasPills && (
          <PillsContainer>
            {over_18 && <NSFWPill>NSFW</NSFWPill>}
          </PillsContainer>
        )}
        <MediaContainer
          secure_media_embed={secure_media_embed}
          secure_media={secure_media}
          preview={preview}
          post_hint={post_hint}
          url={url}
          style={{
            maxWidth: '100%',
            minHeight: '100px',
            maxHeight: '200px',
            borderRadius: '6px',
            overflow: 'hidden'
          }}
          hideVideo
          onLoad={onLoadMedia}
        />
      </PostContainer>
    </Container>
  )
}

export default SubmissionRow
